
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;500;700&display=swap');

:root {
  --weightedEase: cubic-bezier(0.5, 0, 0.1, 1);
}

body {
  margin: 0;
  font-family: 'OpenSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@layer components {

  .button-base-styles {
    @apply cursor-pointer rounded-3xl py-2 pl-4 pr-6
  }

}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.button-isFocused {
  border: 3px solid #c71576;
  color: black;
  background: white;
}

.channel-focused {
  color: white !important;
  transform: scale(1.1);

  .title {
    background: white;
    color: black;
  }
}